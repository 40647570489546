<template>
  <div v-editable="blok" class="sb-spacing-responsive" :style="spacingHeight" />
</template>

<script lang="ts" setup>
import type { SbSpacingResponsiveStoryblok } from '@/types'

interface Props {
  blok: SbSpacingResponsiveStoryblok
}
const props = defineProps<Props>()

const spacingHeight = computed(() => {
  return {
    '--height-mobile': `${props.blok?.spacing?.mobile || 0}px`,
    '--height-tablet': `${props.blok?.spacing?.tablet || 0}px`,
    '--height-desktop': `${props.blok?.spacing?.desktop || 0}px`,
  }
})
</script>

<style lang="scss" scoped>
.sb-spacing-responsive {
  --height-mobile: 0;
  --height-tablet: 0;
  --height-desktop: 0;

  height: var(--height-mobile);

  @media (min-width: $breakpoint-md) {
    height: var(--height-tablet);
  }

  @media (min-width: $breakpoint-lg) {
    height: var(--height-desktop);
  }
}
</style>
